
import { DataOrder_GMFixErrorOrder, DataOrder_GMSearchOrder } from '@/apis/data_order';
import { GetItemConfigid, GetItemName, IsValidItem } from '@/constant/itemconfig';
import { EPayOrderState, EPayType, GetEnumObject } from '@/constant/network';
import { formatJson } from "@/utils";
import { exportJson2Excel } from "@/utils/excel";
import { TodayEnd, TodayStart } from "@/utils/timer";
import { ElForm, ElMessage, ElMessageBox } from 'element-plus';
import { defineComponent, nextTick, onMounted, reactive, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({

  setup() {
    const { t } = useI18n()
    const dataForm = ref<typeof ElForm>()
    const dataMap = reactive({
      time: Date,
      orderstateOptions: GetEnumObject(EPayOrderState),
      paytypeOptions: GetEnumObject(EPayType),

      tableKey: 0,
      list: [] as ITPayOrderItem[],
      total: 1,
      listLoading: true,
      starttime: TodayStart(),
      endtime: TodayEnd(),

      listQuery: {
        page: 1,
        limit: 20,
        orderstate: "",
        paytype: "",
        account: "",
        itemconfigid: "",
        sort: "+id"
      },
      dialogFormVisible: false,
      downloadLoading: false,
      tempLogRecord: { FullMsg: "" },
    })


    const handleCurrentChange = (page: number) => {
      dataMap.listQuery.page = page;
      getLogList();
    };
    const handleSizeChange = (val: any) => {
      // dataMap.getList(null, null, val)
    };
    const getLogList = async () => {
      dataMap.listLoading = true;
      dataMap.list = [];
      let msg = {} as IC2G_GMSearchOrder;
      msg.StartTime = Math.ceil(dataMap.starttime.getTime() / 1000);
      msg.EndTime = Math.ceil(dataMap.endtime.getTime() / 1000);
      msg.Account = dataMap.listQuery.account;
      msg.PayType = dataMap.paytypeOptions[dataMap.listQuery.paytype] || 0;
      msg.OrderState = dataMap.orderstateOptions[dataMap.listQuery.orderstate] || 0;
      msg.PageCount = dataMap.listQuery.limit;
      msg.PageIndex = dataMap.listQuery.page;
      if (dataMap.listQuery.itemconfigid !== "") {
        let itemid = GetItemConfigid(dataMap.listQuery.itemconfigid);
        if (itemid == "") {
          itemid = dataMap.listQuery.itemconfigid;
        }
        if (!IsValidItem(itemid)) {
          ElMessage.error(`itemid or itemname not valid: ${dataMap.listQuery.itemconfigid}`);
          return;
        }
        msg.ItemConfigId = Number(itemid) || 0;
      }
      let cbmsg = await DataOrder_GMSearchOrder(msg);
      if (cbmsg?.Error !== 0) {
        ElMessage.error(`GetOrderFail: ${cbmsg?.Message}`);
        return;
      }
      if (cbmsg.SearchResult) {
        for (let k of cbmsg.SearchResult) {
          let record = JSON.parse(k) as ITPayOrderItem;
          record.Id = record._id;
          record.Index = dataMap.list.length + 1 + (dataMap.listQuery.page - 1) * dataMap.listQuery.limit;
          record.needFix = record.State.includes(EPayOrderState.PaySuccess) && (!record.State.includes(EPayOrderState.PayAddItemSuccess));
          dataMap.list.push(record);
        }
      }
      dataMap.total = cbmsg.SearchCount;
      dataMap.listLoading = false;
    }
    const GetItemDes = (row: ITPayOrderItem) => {
      return `${GetItemName(row.ItemConfigId)}[${row.ItemConfigId}]x${row.ItemCount}`;
    }

    const GetPayTypeDes = (paytype: number) => {
      return EPayType[paytype] as string;
    }
    const GetOrderStateDes = (orderstate: number[]) => {
      let r = "";
      orderstate.forEach(v => {
        r += EPayOrderState[v] + "|";
      })
      r = r.substring(0, r.length - 1);
      return r;
    }
    const GetShotMsg = (row: ITPayOrderItem) => {
      if (row == null || row.ErrorMsg == null) { return "" }
      let arr = row.ErrorMsg.split("\r\n");
      if (arr.length >= 3) {
        let i = 0;
        let r = "";
        while (i < 3) {
          r += arr[i] + "<br>";
          i++;
        }
        return r;
      } else {
        return row.ErrorMsg;
      }
    }
    const GetFullMsg = (row: ITPayOrderItem) => {
      if (row == null || row.ErrorMsg == null) { return "" }
      let arr = row.ErrorMsg.split("\r\n");
      let i = 0;
      let r = "";
      while (i < arr.length) {
        r += arr[i] + "<br>";
        i++;
      }
      return r;
    }

    const handleSingleLogInfo = (row: ITPayOrderItem) => {
      dataMap.tempLogRecord.FullMsg = GetFullMsg(row);
      dataMap.dialogFormVisible = true;
      nextTick(() => {
        (dataForm.value as any).clearValidate()
      });
    }
    const handleFixErrorOrder = (row: ITPayOrderItem) => {
      ElMessageBox.confirm(
        `Confirm to Delete ErrorLog<${row.Id}> ?`,
        "Delete ErrorLog",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      ).then(async () => {
        // todo
        let msg = {} as IC2G_GMIgnoreErrorLog;
        let cbmsg = await DataOrder_GMFixErrorOrder(msg);
        if (cbmsg?.Error == 0) {
          ElMessage.success(`Order<${row.Id}> Fix SUCCESS`)
        } else {
          ElMessage.error(`Order<${row.Id}> Fix Fail`)
        }
      })
        .catch(err => {
          console.error(err);
        });
    }

    const handleDownload = () => {
      dataMap.downloadLoading = true;
      const tHeader = ["Id", "Title", "Label", "ItemConfigId", "ItemCount", "TotalAmount", "PayOrderSource",
        "PlayerId", "CharacterId", "CreateTime", "Account", "PayTime", "ErrorMsg", "State",
      ];
      const filterVal = ["Id", "Title", "Label", "ItemConfigId", "ItemCount", "TotalAmount", "PayOrderSource",
        "PlayerId", "CharacterId", "CreateTime", "Account", "PayTime", "ErrorMsg", "State",];
      const data = formatJson(filterVal, dataMap.list);
      exportJson2Excel(tHeader, data, "DataOrderSearch");
      dataMap.downloadLoading = false;
    }

    onMounted(() => {
      getLogList()
    })

    return {
      t, ...toRefs(dataMap),
      dataForm,
      getLogList,
      GetPayTypeDes,
      GetItemDes,
      GetOrderStateDes,
      GetShotMsg,
      handleCurrentChange,
      handleSizeChange,
      handleSingleLogInfo,
      handleFixErrorOrder,
      handleDownload
    }
  }
})


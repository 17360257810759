/*
 * @Description: 订单数据
 * @Author: ZY
 * @Date: 2020-12-28 14:40:50
 * @LastEditors: scy😊
 * @LastEditTime: 2021-01-20 10:17:09
 */
import https from '@/utils/https'
import { ContentType, Method } from 'axios-mapper'

export const DataOrder_GMSearchOrder = (data: IC2G_GMSearchOrder) => {
    return https().request<IG2C_GMSearchLog>('/GMSearchOrder', Method.POST, data, ContentType.json)
}

export const DataOrder_GMFixErrorOrder = (data: IC2G_GMGMFixErrorOrder) => {
    return https().request<IHttpResponse>('/GMFixErrorOrder', Method.POST, data, ContentType.json)
}